var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"width":_vm.$vuetify.breakpoint.smAndDown ? '100%' : _vm.action === 'new' ? 900 : 600,"persistent":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({style:([
        {
          position: _vm.absolute ? 'absolute' : 'relative',
          right: _vm.absolute ? (_vm.first ? '20px' : '95px') : '',
        } ]),attrs:{"icon":"","disabled":_vm.edit == false}},on),[_c('v-icon',{attrs:{"large":""}},[_vm._v("mdi-plus-circle")])],1)]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.$t("add", { name: _vm.$tc("customer") }))+" ")]),_c('div',{staticClass:"close"},[_c('v-btn',{attrs:{"icon":"","small":""},on:{"click":function($event){_vm.dialog = false;
          _vm.action = null;}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('form',{attrs:{"action":"","data-vv-scope":"extra-customer-form"},on:{"submit":function($event){$event.preventDefault();return _vm.validateForm('extra-customer-form')}}},[(_vm.action === 'new')?_c('v-card-text',[_c('CustomersForm',{ref:"customer_form",attrs:{"customer":_vm.customer,"buttons":false}})],1):(_vm.action === 'existing')?_c('v-card-text',[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{staticClass:"py-0",attrs:{"cols":"8"}},[_c('label',{domProps:{"textContent":_vm._s(_vm.$tc('customer'))}}),_c('v-autocomplete',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],attrs:{"outlined":"","hide-details":"","dense":"","items":_vm.customers,"item-value":"id","search-input":_vm.searchCustomer,"loading":_vm.isLoadingCustomer,"cache-items":"","item-text":"user.fullname","data-vv-validate-on":"blur","error-messages":_vm.errors.collect('extra-customer-form.customer'),"data-vv-name":"customer"},on:{"update:searchInput":function($event){_vm.searchCustomer=$event},"update:search-input":function($event){_vm.searchCustomer=$event}},model:{value:(_vm.customer_id),callback:function ($$v) {_vm.customer_id=$$v},expression:"customer_id"}})],1)],1)],1):_c('v-card-text',[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"8","md":"6"}},[_c('base-border-card',{attrs:{"title":_vm.$t('customers.new'),"two_lines":true},on:{"card_clicked":function($event){return _vm.select('new')}}})],1),_c('v-col',{attrs:{"cols":"8","md":"6"}},[_c('base-border-card',{attrs:{"title":_vm.$t('existing', { name: _vm.$tc('customer') }),"two_lines":true},on:{"card_clicked":function($event){return _vm.select('existing')}}})],1)],1)],1),(_vm.action)?_c('v-card-actions',[_c('v-spacer'),[_c('v-btn',{staticClass:"mr-3",staticStyle:{"height":"25px"},attrs:{"outlined":"","elevation":"0"},on:{"click":function($event){_vm.dialog = false;
              _vm.action = null;}}},[_vm._v(_vm._s(_vm.$t("cancel")))]),_c('v-btn',{staticStyle:{"color":"#363533","height":"25px","padding-top":"10px !important"},attrs:{"elevation":"0","type":"submit"}},[_c('v-icon',{staticStyle:{"margin-right":"5px"},attrs:{"size":"14px"}},[_vm._v("$save")]),_vm._v(" "+_vm._s(_vm.$t("save", { name: "" }))+" ")],1)]],2):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }