<template>
  <v-dialog
    :width="
      $vuetify.breakpoint.smAndDown ? '100%' : action === 'new' ? 900 : 600
    "
    v-model="dialog"
    persistent
  >
    <template v-slot:activator="{ on }">
      <v-btn
        icon
        v-on="on"
        :disabled="edit == false"
        v-bind:style="[
          {
            position: absolute ? 'absolute' : 'relative',
            right: absolute ? (first ? '20px' : '95px') : '',
          },
        ]"
      >
        <v-icon large>mdi-plus-circle</v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-card-title>
        {{ $t("add", { name: $tc("customer") }) }}
      </v-card-title>
      <div class="close">
        <v-btn
          icon
          @click="
            dialog = false;
            action = null;
          "
          small
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
      <form
        action
        data-vv-scope="extra-customer-form"
        @submit.prevent="validateForm('extra-customer-form')"
      >
        <v-card-text v-if="action === 'new'">
          <CustomersForm
            :customer="customer"
            :buttons="false"
            ref="customer_form"
          />
        </v-card-text>
        <v-card-text v-else-if="action === 'existing'">
          <v-row justify="center">
            <v-col class="py-0" cols="8">
              <label v-text="$tc('customer')"></label>
              <v-autocomplete
                outlined
                hide-details
                dense
                v-model="customer_id"
                :items="customers"
                item-value="id"
                :search-input.sync="searchCustomer"
                :loading="isLoadingCustomer"
                cache-items
                item-text="user.fullname"
                data-vv-validate-on="blur"
                v-validate="'required'"
                :error-messages="errors.collect('extra-customer-form.customer')"
                data-vv-name="customer"
              ></v-autocomplete>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-text v-else>
          <v-row justify="center">
            <v-col cols="8" md="6">
              <base-border-card
                :title="$t('customers.new')"
                @card_clicked="select('new')"
                :two_lines="true"
              >
              </base-border-card>
            </v-col>
            <v-col cols="8" md="6">
              <base-border-card
                :title="$t('existing', { name: $tc('customer') })"
                @card_clicked="select('existing')"
                :two_lines="true"
              >
              </base-border-card>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions v-if="action">
          <v-spacer></v-spacer>
          <template>
            <v-btn
              outlined
              @click="
                dialog = false;
                action = null;
              "
              style="height: 25px"
              elevation="0"
              class="mr-3"
              >{{ $t("cancel") }}</v-btn
            >
            <v-btn
              style="color: #363533; height: 25px; padding-top: 10px !important"
              elevation="0"
              type="submit"
            >
              <v-icon style="margin-right: 5px" size="14px">$save</v-icon>
              {{ $t("save", { name: "" }) }}
            </v-btn>
          </template>
        </v-card-actions>
      </form>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from "vuex";
import constants from "@/constants";
export default {
  name: "AddExtraCustomer",
  props: ["appointment", "absolute", "first", "edit"],
  components: {
    CustomersForm: () => import("@/components/customer/Form"),
  },
  watch: {
    searchCustomer(val) {
      //console.log(val);
      this.fetchCustomers();
    },
  },
  data() {
    return {
      isLoadingCustomer: false,
      searchCustomer: "",
      dialog: false,
      action: null,
      customers: [],
      customer_id: null,
      customer: { user: { role_id: constants.roles.CUSTOMER } },
    };
  },
  methods: {
    select(type) {
      this.action = type;
    },
    ...mapActions("appointments", ["addExistingCustomer", "addNewCustomer"]),
    async validateForm(scope) {
      let result = await this.$validator.validateAll(scope);
      if (result) {
        console.log("validated");
        if (this.action === "existing") {
          this.addExistingCustomer({
            id: this.appointment.id,
            data: { customer_id: this.customer_id, first: this.first },
          }).then(() => {
            this.dialog = false;
            this.action = null;
            this.customer_id = null;
            this.$emit("update");
          });
        } else {
          let res2 = await this.$refs.customer_form.validateForm(
            "customer-form"
          );
          if (res2) {
            this.addNewCustomer({
              id: this.appointment.id,
              data: { ...this.customer, first: this.first },
            }).then(() => {
              this.dialog = false;
              this.action = null;
              this.customer = { user: { role_id: constants.roles.CUSTOMER } };
              this.$emit("update");
            });
          }
        }
      }
    },
    ...mapActions("customers", ["getCustomers"]),
    fetchCustomers() {
      //if (this.isLoadingCustomer) return;
      this.isLoadingCustomer = true;
      let filters = {
        itemsPerPage: 20,
        search: this.searchCustomer,
      };
      if (this.$store.getters["auth/isStudio"])
        filters.studio_id = this.$store.state.auth.user.studio.id;
      if (this.$store.getters["auth/isTattooer"])
        filters.tattooer_id = this.$store.state.auth.user.tattooer.id;
      this.getCustomers({
        filters,
        pagination: {},
      }).then((response) => {
        let cs = response.data.filter((c) => {
          return !this.appointment.extra_customers
            .map((ec) => ec.id)
            .includes(c.id);
        });
        this.customers = cs;
        this.isLoadingCustomer = false;
      });
    },
  },
};
</script>
<style scoped lang="sass">
label
  //line-height: 1
  height: 25px
  display: block
</style>
